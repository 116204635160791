import React, { useEffect, useState } from 'react';
import { Check } from 'lucide-react';
import { useEntiContext } from '@/services/context';
import i8n from '@/services/lang/i8n';

const PricingCard = ({
  title,
  price,
  features,
  isPopular,
  currency,
  period,
  __,
}) => {
  const [valorDolar, setValorDolar] = useState(1000);

  // useEffect(() => {
  //   (async () => {
  //     const indicadores = await (
  //       await fetch('https://mindicador.cl/api/dolar')
  //     ).json();
  //     setValorDolar(indicadores?.serie[0]?.valor || 1000);
  //   })();
  // }, []);

  const formatPrice = (basePrice, dollar) => {
    const multiplier = period === 'annual' ? 10 : 1;
    const currencyRate = currency === 'CLP' ? 1000 : 1;
    const finalPrice = basePrice * multiplier * currencyRate;

    return new Intl.NumberFormat(currency === 'CLP' ? 'es-CL' : 'en-US', {
      style: 'decimal',
      minimumFractionDigits: currency === 'CLP' ? 0 : 0,
      maximumFractionDigits: currency === 'CLP' ? 0 : 0,
    }).format(finalPrice);
  };

  return (
    <div
      className={`relative p-6 rounded-lg shadow-lg flex flex-col h-full ${
        isPopular
          ? 'bg-purple-50 border-2 border-purple-500'
          : 'bg-white border border-gray-200'
      }`}
    >
      {isPopular && (
        <span className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-purple-500 text-white px-4 py-1 rounded-full text-sm font-semibold">
          {__('MOST_POPULAR')}
        </span>
      )}
      <h3 className="text-xl font-bold text-gray-900 mb-4">{title}</h3>
      <div className="mb-6">
        <span className="text-[2.0rem] font-bold text-gray-900">
          {currency === 'CLP' ? '$' : '$'}
          {formatPrice(price, valorDolar)}
          {currency === 'CLP' ? ' + IVA' : ''}
        </span>
        <span className="text-gray-600">
          /{period === 'annual' ? __('YEAR') : __('MONTH')}
        </span>
        {period === 'annual' && (
          <div className="text-sm text-green-600 mt-2">
            {__('TWO_MONTHS_FREE')}
          </div>
        )}
      </div>
      <ul className="space-y-4 mb-8 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-3">
            <Check className="w-5 h-5 text-green-500 flex-shrink-0" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
      <button
        className={`w-full py-3 px-4 rounded-lg font-semibold transition-colors mt-auto cursor-pointer ${
          isPopular
            ? 'bg-purple-500 text-white hover:bg-purple-600'
            : 'bg-gray-900 text-white hover:bg-gray-800'
        }`}
      >
        {__('START_NOW')}
      </button>
    </div>
  );
};

const PricingComponent = ({ defaultCurrency = 'USD' }) => {
  const [currency, setCurrency] = useState(defaultCurrency);
  const [period, setPeriod] = useState('monthly');
  const { lang } = useEntiContext();
  const __ = i8n(lang);

  const plans = [
    {
      title: __('BASIC_PLAN'),
      price: 50,
      features: [
        __('BASIC_FEATURE_1'),
        __('BASIC_FEATURE_2'),
        __('BASIC_FEATURE_3'),
      ],
      isPopular: false,
    },
    {
      title: __('PRO_PLAN'),
      price: 80,
      features: [
        __('PRO_FEATURE_1'),
        __('PRO_FEATURE_2'),
        __('PRO_FEATURE_3'),
        __('PRO_FEATURE_4'),
        __('PRO_FEATURE_5'),
        __('PRO_FEATURE_6'),
      ],
      isPopular: true,
    },
    {
      title: __('ENTERPRISE_PLAN'),
      price: 150,
      features: [
        __('ENTERPRISE_FEATURE_1'),
        __('ENTERPRISE_FEATURE_2'),
        __('ENTERPRISE_FEATURE_3'),
        __('ENTERPRISE_FEATURE_4'),
        __('ENTERPRISE_FEATURE_5'),
        __('ENTERPRISE_FEATURE_6'),
        __('ENTERPRISE_FEATURE_7'),
      ],
      isPopular: false,
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-16">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-purple-600 mb-16">
          {__('PRICING_TITLE')}
        </h2>
        {/* <p className="text-lg text-gray-600 mb-8">{__('PRICING_SUBTITLE')}</p> */}

        <div className="flex justify-center gap-4 mb-8">
          <div className="inline-flex rounded-lg border border-gray-200 p-1">
            <button
              onClick={() => setCurrency('USD')}
              className={`px-4 py-2 rounded-md text-sm font-medium cursor-pointer ${
                currency === 'USD'
                  ? 'bg-orange-500 text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              USD
            </button>
            <button
              onClick={() => setCurrency('CLP')}
              className={`px-4 py-2 rounded-md text-sm font-medium cursor-pointer ${
                currency === 'CLP'
                  ? 'bg-orange-500 text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              CLP
            </button>
          </div>

          <div className="inline-flex rounded-lg border border-gray-200 p-1">
            <button
              onClick={() => setPeriod('monthly')}
              className={`px-4 py-2 rounded-md text-sm font-medium cursor-pointer ${
                period === 'monthly'
                  ? 'bg-orange-500 text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              {__('MONTHLY')}
            </button>
            <button
              onClick={() => setPeriod('annual')}
              className={`px-4 py-2 rounded-md text-sm font-medium cursor-pointer ${
                period === 'annual'
                  ? 'bg-orange-500 text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              {__('ANNUAL')}
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <PricingCard
            key={index}
            {...plan}
            currency={currency}
            period={period}
            __={__}
          />
        ))}
      </div>
    </div>
  );
};

export default PricingComponent;
