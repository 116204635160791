import { useEntiContext } from '@/services/context';
import i8n from '@/services/lang/i8n';
import { useEffect, useState } from 'react';

const MenuItem = ({ href, onClick, children }) => (
  <a
    href={href}
    onClick={onClick}
    className="no-underline font-bold text-purple-500 hover:text-purple-700 transition-colors"
  >
    {children}
  </a>
);

function goTop(e) {
  e.preventDefault();
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function goTo(section, e) {
  e.preventDefault();
  document
    .querySelector(`a[name="${section}"]`)
    ?.scrollIntoView({ behavior: 'smooth' });
}

export const PageMenu = () => {
  const [forceLanguage, setForceLanguage] = useState(false);

  const { lang } = useEntiContext();
  const __ = i8n(lang, forceLanguage);

  useEffect(() => {
    if (lang) {
      setForceLanguage(true);
    }
  }, [lang]);

  return (
    <nav className="flex items-center space-x-10">
      <MenuItem href="#" onClick={goTop}>
        {__('MENU_HOME')}
      </MenuItem>
      <MenuItem href="#integrations" onClick={(e) => goTo('integrations', e)}>
        {__('MENU_INTEGRATIONS')}
      </MenuItem>
      <MenuItem href="#pricing" onClick={(e) => goTo('pricing', e)}>
        {__('MENU_PRICING')}
      </MenuItem>
      <MenuItem href="#contact" onClick={(e) => goTo('contact', e)}>
        {__('MENU_CONTACT')}
      </MenuItem>
    </nav>
  );
};
