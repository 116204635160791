'use client';
import { Card, Dropdown, Space } from 'antd';
import { useRouter } from 'next/navigation';
import { PageMenu } from './pageMenu';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { createClient } from '@/services/supabase/client';
import { useEffect, useState } from 'react';
import { unstable_noStore as noStore } from 'next/cache';
import { useEntiContext } from '@/services/context';
export const dynamic = 'force-dynamic';
import HubspotContactForm from '@/components/HubspotForm';
import i8n from '@/services/lang/i8n';
import PricingComponent from './Pricing';
noStore();

export default function Home() {
  const router = useRouter();
  const supabase = createClient();
  const [forceLanguage, setForceLanguage] = useState(false);
  const { lang, setLang } = useEntiContext();
  const __ = i8n(lang, forceLanguage);
  const [user, setUser] = useState({});
  const [serverLang, setServerLang] = useState(
    typeof document !== 'undefined' ? document?.cookie?.match(/NEXT_LOCALE=(es|en)/)?.[1] : 'en',
  );

  const handleGotoContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  const languageItems = [
    {
      key: 'en',
      label: 'English',
      onClick: () => {
        setLang('en');
        setForceLanguage(true);
        document.cookie = `NEXT_LOCALE=en`;
      },
    },
    {
      key: 'es',
      label: 'Español',
      onClick: () => {
        setLang('es');
        setForceLanguage(true);
        document.cookie = `NEXT_LOCALE=es`;
      },
    },
  ];

  const signOut = async () => {
    const supabase = createClient();
    await supabase.auth.signOut();
    localStorage.removeItem('activeCompanyId');
    document.cookie = `activeCompanyId=`;
    setUser({});
  };

  useEffect(() => {
    (async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    })();
  }, [supabase]);
  return (
    // __('LANGUAGE') && (
    (
      <>
        <header className="fixed top-0 left-0 right-0 z-50 transition-all duration-200 glassmorphism shadow-sm">
          <nav className="xcontainer mx-auto px-2 py-2 flex items-center justify-between">
            <img
              src="/logo-enti.png"
              alt={__('ENTI_LOGO_ALT')}
              className="h-16 w-16 rounded-full bg-purple-600 text-white"
            />
            <PageMenu />
            <div className="flex gap-3 items-center justify-between">
              {user?.id ? (
                <div>
                  {/* {user.user_metadata?.name} */}
                  <button
                    className="px-4 py-2 ml-5 bg-purple-500 cursor-pointer hover:bg-purple-400 active:bg-purple-600 text-white rounded-md"
                    onClick={() => router.push(__('LINK_ENVIOS'))}
                  >
                    {__('GO_TO_PANEL')}
                  </button>
                  <button
                    className="px-4 py-2 ml-1 bg-purple-500 cursor-pointer hover:bg-purple-400 active:bg-purple-600 text-white rounded-md"
                    // className="py-2 px-4  text-black no-underline bg-transparent cursor-pointer"
                    onClick={signOut}
                  >
                    <LogoutOutlined />
                  </button>
                </div>
              ) : (
                <button
                  className="px-4 py-2 bg-purple-500 cursor-pointer hover:bg-purple-400 active:bg-purple-600 text-white rounded-md"
                  onClick={() => router.push('/login')}
                >
                  {__('ACCESS')}
                </button>
              )}
              <div
                style={{
                  minWidth: 100,
                  borderBottom: '2px solid transparent',
                  // background: 'gray',
                }}
                className="items-center ml-5 mr-0"
              >
                {lang ? (
                  <Dropdown menu={{ items: languageItems }}>
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="cursor-pointer text-orange-500 font-semibold"
                    >
                      {languageItems.find((l) => l.key === lang).label}
                      <DownOutlined className="text-xs font-bold ml-1 -translate-y-px" />
                    </a>
                  </Dropdown>
                ) : (
                  ' '
                )}
              </div>
            </div>
          </nav>
        </header>
        <section className="bg-gradient-to-br from-blue-400 to-purple-600 text-white py-20 mt-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-8">
                {__('HERO_TITLE')}
              </h1>
              <p className="text-xl mb-8">{__('HERO_SUBTITLE')}</p>
              <button
                onClick={handleGotoContact}
                className="px-6 py-3  text-purple-600 rounded-md cursor-pointer border-solid hover:bg-purple-500 hover:text-white font-semibold transition hover:border-white border-white"
              >
                {__('AVOID_NOW')}
              </button>
            </div>
          </div>
        </section>
        <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <h2 className="text-3xl font-bold text-purple-600 mb-8">
                    {__('STAT_TITLE')}
                  </h2>
                  <h3 className="text-2xl font-semibold mb-4">
                    {__('CUSTOMER_OPINION_TITLE')}
                  </h3>
                  <p className="text-gray-600 mb-4">
                    {__('CUSTOMER_OPINION_DESC')}
                  </p>
                  <p className="text-2xl font-semibold mb-4">
                    {__('ONE_STAR_TITLE')}
                  </p>
                  <p className="text-gray-600 mb-4">{__('ONE_STAR_DESC')}</p>
                  <p className="text-2xl font-semibold mb-4">
                    {__('BAD_REVIEWS_TITLE')}
                  </p>
                  <p className="text-gray-600 text mb-4">
                    {__('BAD_REVIEWS_DESC')}
                  </p>
                </div>
                <div>
                  <img
                    src={__('RATING_INTERFACE_IMG')}
                    alt={__('RATING_INTERFACE_ALT')}
                    className="w-full rounded-lg shadow-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-purple-600 text-white py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              {__('ENTI_BENEFIT_TITLE')}
            </h2>
            <p className="max-w-2xl mx-auto">{__('ENTI_BENEFIT_DESC')}</p>
          </div>
        </section>
        <a name="integrations"></a>
        <section className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-purple-600 mb-12 text-center">
              {__('INTEGRATIONS_TITLE')}
            </h2>
            <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
              <div>
                <h3 className="text-xl font-semibold mb-4">
                  {__('API_TITLE')}
                </h3>
                <p className="text-gray-600">{__('API_DESC')}</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-4">
                  {__('ADVISORY_TITLE')}
                </h3>
                <p className="text-gray-600">{__('ADVISORY_DESC')}</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-4">
                  {__('TECHNOLOGY_TITLE')}
                </h3>
                <p className="text-gray-600">{__('TECHNOLOGY_DESC')}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-purple-600 text-white py-16">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center p-5 bg-white text-black">
              <blockquote className="text-xl md:text-2xl mb-8">
                {__('TESTIMONIAL_QUOTE')}
              </blockquote>
              <p className="font-semibold">{__('TESTIMONIAL_AUTHOR')}</p>
            </div>
          </div>
        </section>
        <section className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <a name="pricing"></a>
            <PricingComponent
              defaultCurrency={serverLang === 'es' ? 'CLP' : 'USD'}
            />
            <div className="text-center text-sm text-gray-600 mt-4">
              {__('PRICING_DEVELOPMENT')}
            </div>
          </div>
        </section>
        <section className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <a name="contact"></a>
            <h2
              className="text-3xl font-bold text-purple-600 mb-12 text-center"
              id="contact"
            >
              {__('CONTACT_TITLE')}
            </h2>
            <Card className="w-1/2 m-auto shadow-sm">
              <HubspotContactForm
                portalId="24157753"
                formId="f3612fdd-46a0-4db3-9fe2-931004162327"
              />
            </Card>
          </div>
        </section>
        <footer className="bg-purple-600 text-white py-4">
          <div className="container mx-auto px-4 text-center text-sm">
            <p>
              <a
                href={__('PRIVACY_POLICY_URL')}
                className="text-white-important no-underline hover:underline"
              >
                {__('PRIVACY_POLICY')}
              </a>
            </p>
          </div>
        </footer>
      </>
    )
  );
}
